import i18next from "i18next";
import { Lang } from "../../enums/lang";
import './rulesOlsenPage.scss'
import { useState } from "react";

export const RulesOlsenPage = () => {

    const [selectedRule] = useState<string>(getRuleFromLocale(i18next.language as Lang))

    const rulesNumberOfPages = 8;

    let imageMarkup = new Array(rulesNumberOfPages).fill('').map((_, index) => {
        return <div key={index} className="d-flex justify-content-center">
            <img className="py-2 image-styling" key={index} src={`/img/${selectedRule}/${selectedRule}-${index + 1}.png`} alt={`slide#${index + 1}`} />
        </div>
    })

    return (
        <div className="" >
            <div className="h-100 overflow-scroll pb-5 mb-5" >
                {imageMarkup}
            </div>
        </div>
    );
}

const getRuleFromLocale = (rule: Lang): string => {
    switch (rule) {
        case Lang.Dk: return "RulesDk"
        case Lang.En: return "RulesEn"
        case Lang.Fo: return "RulesFo"
        default: return "RulesEn"
    }
}