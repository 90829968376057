import { ProductDialog } from "components/productDialog/productDialog";
import { ProductInfoListItem } from "components/productTile/productInfoListItem.interface";
import { ProductTile } from "components/productTile/productTile";
import { t } from "i18next";
import { useEffect, useState } from "react";

export const ProductsPage = () => {
    const [selectedProductTile, setSelectedProductTile] = useState<ProductInfoListItem | undefined>();

    // todo: fetch from somewhere else
    const [tileData] = useState<ProductInfoListItem[]>([
        {
            id: "208485d3-182c-49c4-bfb2-d075cbb5b29f",
            imgUrl: "img/Products/Olsen 4.jpg",
            rulesUrl: "/rules/olsen/",
            productImgs: [
                { imgUrl: "img/Products/Olsen 1.jpg", imgId: "imageId1", imgUrlSmall: "img/Products/256/Olsen 1.jpg", imgUrlLarge: "img/Products/650/Olsen 1.jpg" },
                { imgUrl: "img/Products/Olsen 2.jpg", imgId: "imageId2", imgUrlSmall: "img/Products/256/Olsen 2.jpg", imgUrlLarge: "img/Products/650/Olsen 2.jpg" },
                { imgUrl: "img/Products/Olsen 3.jpg", imgId: "imageId3", imgUrlSmall: "img/Products/256/Olsen 3.jpg", imgUrlLarge: "img/Products/650/Olsen 3.jpg" },
                { imgUrl: "img/Products/Olsen 4.jpg", imgId: "imageId4", imgUrlSmall: "img/Products/256/Olsen 4.jpg", imgUrlLarge: "img/Products/650/Olsen 4.jpg" },
                { imgUrl: "img/Products/Olsen 5.jpg", imgId: "imageId5", imgUrlSmall: "img/Products/256/Olsen 5.jpg", imgUrlLarge: "img/Products/650/Olsen 5.jpg" },
            ],
            title: t("olsen"),
            grouping: ['Brettspøl'],
            price: 200,
            sortPosition: 0,
        },
        {
            id: "791d4876-12ed-413e-8151-a4316429d8f1",
            imgUrl: "img/Products/Ludo_2022_003_1.jpg",
            productImgs: [
                { imgUrl: "img/Products/Ludo_2022_003_1.jpg", imgId: "imageId1", imgUrlSmall: "img/Products/256/Ludo_2022_003_1.jpg", imgUrlLarge: "img/Products/650/Ludo_2022_003_1.jpg" },
                { imgUrl: "img/Products/Ludo_2022_006.jpg", imgId: "imageId3", imgUrlSmall: "img/Products/256/Ludo_2022_006.jpg", imgUrlLarge: "img/Products/650/Ludo_2022_006.jpg" },
                { imgUrl: "img/Products/Ludo_2022_010.jpg", imgId: "imageId4", imgUrlSmall: "img/Products/256/Ludo_2022_010.jpg", imgUrlLarge: "img/Products/650/Ludo_2022_010.jpg" },
                { imgUrl: "img/Products/Ludo_2022_012.jpg", imgId: "imageId5", imgUrlSmall: "img/Products/256/Ludo_2022_012.jpg", imgUrlLarge: "img/Products/650/Ludo_2022_012.jpg" },
            ],
            title: t("ludo"),
            price: 299.95,
            grouping: ['Brettspøl'],
            shortDescription: t('shortDescription-ludo'),
            sortPosition: 1,
        },
        {
            id: "b24925e5-c72d-47ef-8bb9-4092cd0828bd",
            imgUrl: "img/Products/bøur.jpg",
            productImgs: [
                { imgUrl: "img/Products/bøur.jpg", imgId: "imageId1", imgUrlSmall: "img/Products/256/bøur.jpg", imgUrlLarge: "img/Products/650/bøur.jpg" },
            ],
            title: t("field"),
            shortDescription: t('shortDescription-bour'),
            price: 199.95,
            grouping: ['Puslispøl'],
            sortPosition: 2,
        },
        {
            id: "38da8d69-b125-4c45-9b68-4701898889d4",
            imgUrl: "img/Products/stari.jpg",
            productImgs: [
                { imgUrl: "img/Products/stari.jpg", imgId: "imageId1", imgUrlSmall: "img/Products/256/stari.jpg", imgUrlLarge: "img/Products/650/stari.jpg" },
            ],
            title: t("sparrow"),
            shortDescription: t("shortDescription-starling"),
            price: 199.95,
            sortPosition: 3,
        },
        {
            id: "c3333681-ca6f-4b95-a076-f64833b32e59",
            imgUrl: "img/Products/Súla.jpg",
            productImgs: [
                { imgUrl: "img/Products/Súla.jpg", imgId: "imageId1", imgUrlSmall: "img/Products/256/Súla.jpg", imgUrlLarge: "img/Products/650/Súla.jpg" },
            ],
            title: t("sula"),
            shortDescription: t("shortDescription-gannet"),
            price: 199.95,
            sortPosition: 4,
        },
        {
            id: "b77bf7bf-2e29-4452-90d8-3ee6599b24c9",
            imgUrl: "img/Products/Toftavatn.jpg",
            productImgs: [
                { imgUrl: "img/Products/Toftavatn.jpg", imgId: "imageId1", imgUrlSmall: "img/Products/256/Toftavatn.jpg", imgUrlLarge: "img/Products/650/Toftavatn.jpg" },
            ],
            shortDescription: t("shortDescription-toftavatn"),
            title: t("toftavatn"),
            price: 299.95,
            sortPosition: 5,
        },
        {
            id: "f03b5672-7e69-47d2-8e90-1a7fd565d604",
            imgUrl: "img/Products/Ær.jpg",
            productImgs: [
                { imgUrl: "img/Products/Ær.jpg", imgId: "imageId1", imgUrlSmall: "img/Products/256/Ær.jpg", imgUrlLarge: "img/Products/650/Ær.jpg" },
            ],
            title: t("aer"),
            shortDescription: t("shortDescription-aer"),
            price: 299.95,
            sortPosition: 6,
        },
    ]);

    const onClose = () => {
        setSelectedProductTile(undefined);
    };

    useEffect(() => {
        setSelectedProductTile(
            tileData?.find((_) => _.id === selectedProductTile?.id)
        );
    }, [tileData, selectedProductTile]);

    return (
        <div
            className="d-flex justify-content-center mt-5"
            style={{ paddingLeft: "10vw", paddingRight: "10vw" }}
        >
            <div className="d-flex flex-row justify-content-center flex-wrap ">
                {tileData
                    .sort((a, b) => a.sortPosition - b.sortPosition)
                    .map((tile, index) => {
                        return (
                            <ProductTile
                                key={index}
                                productTile={tile}
                                setSelectedProductTile={setSelectedProductTile}
                            />
                        );
                    })}
            </div>
            <ProductDialog
                selectedProductTile={selectedProductTile}
                onClose={onClose}
            />
        </div>
    );
};
