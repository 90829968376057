import './productTile.scss'
import { ProductTileProps } from './ProductTileProps.interface'

export const ProductTile = ({ setSelectedProductTile, productTile }: ProductTileProps) => {
    return (
        <div className="product-tile" key={productTile?.title} onClick={() => setSelectedProductTile(productTile)}>
            <img src={productTile?.imgUrl} height={300} width={300} alt={productTile?.title} />
            <h5 > {productTile?.title}</h5>
            <p className="text-center text-secondary"> kr.{productTile?.price} </p>
        </div>
    )
}

