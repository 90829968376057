import { Navigate, Route, Routes } from 'react-router-dom'
import { RulesOlsenPage } from './pages/rules/rulesOlsenPage'
import { App } from './App'
import { ProductsPage } from 'pages/products/productsPage'
import { withTranslation } from 'react-i18next'

const AppRouter = () => {
    return (

        <Routes>
            <Route element={<App />}>
                {/* <Route index element={<Home />} /> */}
                <Route index element={<Navigate to={"/products"} />} />
                <Route path="/rules/olsen" element={<RulesOlsenPage />} />
                <Route path="/products" element={<ProductsPage />} />
            </Route>
        </Routes>
    )
}

export default withTranslation()(AppRouter);