import "./ImageCarousel.scss";
import { ImageCarouselProps } from "./ImageCarouselProps.interface";

export const ImageCarousel = ({ imgs, selectedImage, setSelectedImage }: ImageCarouselProps) => {

    return (
        <div className="carousel h-100">
            {imgs?.map((productImg, index) => (
                <img
                    key={index}
                    onClick={() => setSelectedImage(productImg)}
                    className={`carouselImage h-100 ${selectedImage === productImg ? "selected" : ""}`}
                    src={productImg.imgUrlSmall}
                    alt={`carouselImage ${index}`}
                />
            ))}
        </div>
    );
};