import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './productDialog.scss'
import { ProductDialogProps } from "./productDialog.interface";
import { ImageCarousel } from "components/ImageCarousel/ImageCarousel";
import { ProductImage } from "components/productTile/productInfo.interface";
import { Link } from "react-router-dom";
import { t } from "i18next";

export const ProductDialog = ({ selectedProductTile, onClose }: ProductDialogProps) => {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState<ProductImage | undefined>()

    useEffect(() => {
        selectedProductTile ? setShow(true) : setShow(false)
        setSelectedImage(selectedProductTile?.productImgs?.[0])
    }, [selectedProductTile])

    return (
        <Modal show={show} onHide={onClose} dialogClassName="product-dialog-style" size="lg">
            <Modal.Header className="bg-primary">
                <Modal.Title> {selectedProductTile?.title} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-style">
                <div className="d-flex flex-row h-100">
                    <div className={`d-flex flex-row mt-auto selectable-images-wrapper ${selectedProductTile?.productImgs?.length! > 1 ? '' : 'hide'}`}>
                        <ImageCarousel imgs={selectedProductTile?.productImgs} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                    </div>
                    <div className="selected-image-wrapper">
                        <img hidden={!selectedImage} src={selectedImage?.imgUrlLarge} className="selected-image-style" alt={`product ${selectedImage?.imgId}`} />
                        <div className="product-description-wrapper">
                            {/* <header className="header d-flex"> Produkt frágreiðing </header> */}

                            <div className={`product-info-tile`} hidden={selectedProductTile?.shortDescription ? false : true}>
                                <div> {t('shortDescription')}</div>
                                <div> {selectedProductTile?.shortDescription}</div>
                            </div>

                            <div className={`product-info-tile`} hidden={selectedProductTile?.price ? false : true}>
                                <div> {t('price')} </div>
                                <div>{selectedProductTile?.price} kr</div>
                            </div>

                            <div className={`product-info-tile`} hidden={selectedProductTile?.rulesUrl ? false : true}>
                                <div> {t('rules')} </div>
                                <Link className="text-dark" to={selectedProductTile?.rulesUrl!}> {t('seeHere')}</Link>
                            </div>

                            <div className={`product-info-tile`} hidden={selectedProductTile?.photographer ? false : true}>
                                <div>{t('pictureBy')}</div>
                                <div> {selectedProductTile?.photographer}</div>
                            </div>
                        </div>

                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="bg-primary">
                <Button variant="secondary" onClick={onClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
